import { LoadingDots } from '@hexa-ui/components';
import ItemDetailsPage from '@pages/ItemDetailsPage/ItemDetailsPage';
import { useHasPermission, useSidebar } from 'admin-portal-shared-services';
import { ProductListPageIcon } from 'components';
import { BulkEditPageIcon, CategoryListPageIcon } from 'components/sidebarIcons/SidebarIcons';
import { Suspense, lazy } from 'react';
import { useIntl } from 'react-intl';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import permissionConstants from '@config/permissionConstants';
import RouterProvider from './providers/RouterProvider/RouterProvider';

const CatalogListPage = lazy(() => import('@pages/CatalogListPage/CatalogListPage'));
const BulkImporterPage = lazy(() => import('@pages/BulkImporterPage/BulkImporterPage'));
const ImportFilePage = lazy(() => import('@pages/ImportFilePage/ImportFilePage'));
const ProductPage = lazy(() => import('@pages/ProductPage/ProductPage'));
const VariantPage = lazy(() => import('@pages/VariantPage/VariantPage'));
const CreateVariantPage = lazy(() => import('@pages/CreateVariantPage/CreateVariantPage'));
const CreateProductPage = lazy(() => import('@pages/CreateProductPage/CreateProductPage'));
const CategoryListPage = lazy(() => import('@pages/CategoryListPage/CategoryListPage'));
const CreateCategoryPage = lazy(() => import('@pages/CreateCategoryPage/CreateCategoryPage'));
const CategoryDetailsPage = lazy(() => import('@pages/CategoryDetailsPage/CategoryDetailsPage'));
const ProtectedRoute = lazy(() => import('@pages/ProtectedRoute/ProtectedRoute'));

export const BASENAME = 'catalog-admin';

export const enum ROUTES {
  CATALOG = '/catalog',
  PRODUCTS_LIST = '/catalog/products',
  ITEMS_LIST = '/catalog/items',
  PRODUCT_DETAILS = '/catalog/product/details',
  VARIANT_DETAILS = '/variant',
  VARIANT_CREATION = '/variant/create',
  PRODUCT_CREATION = '/catalog/product/create',
  BULK_LIST = '/bulk',
  BULK_CREATION = '/bulk/create',
  CATEGORY_LIST = '/category',
  CATEGORY_DETAILS = '/category/details',
  CATEGORY_CREATION = '/category/create',
  ITEM_DETAILS = '/catalog/items/details',
}

export enum RouterParams {
  PRODUCT_ID = 'productId',
  VARIANT_ID = 'variantId',
  CATEGORY_ID = 'categoryId',
  COUNTRY = 'country',
  STORE = 'store',
  ITEM_ID = 'itemId',
}

export enum TabValues {
  PRODUCTS = 'products',
  ITEMS = 'items',
}

const Router = (): React.ReactElement => {
  const { formatMessage } = useIntl();

  const hasAccessToReadCategory = useHasPermission([permissionConstants.CATEGORY_READ]);
  const hasAccessToReadItems = useHasPermission([permissionConstants.ITEM_READ]);
  const hasAccessToReadBulk = useHasPermission(
    [permissionConstants.PRODUCT_WRITE, permissionConstants.ITEM_WRITE],
    'OR'
  );
  const hasAccessToWriteCategory = useHasPermission([permissionConstants.CATEGORY_WRITE]);
  const hasAccessToReadProduct = useHasPermission([permissionConstants.PRODUCT_READ]);
  const hasAccessToCreateProduct = useHasPermission([permissionConstants.PRODUCT_WRITE]);

  const sidebar = {
    items: [
      {
        id: 'catalog-admin-products',
        title: formatMessage({ id: 'CatalogListPage.TITLE' }),
        icon: ProductListPageIcon,
        path: `/${BASENAME}${ROUTES.CATALOG}`,
      },
      hasAccessToReadBulk && {
        id: 'catalog-admin-bulk-edit',
        title: 'Bulk Importer',
        icon: BulkEditPageIcon,
        path: `/${BASENAME}${ROUTES.BULK_LIST}`,
      },
      hasAccessToReadCategory && {
        id: 'catalog-admin-category-list',
        title: 'Category',
        icon: CategoryListPageIcon,
        path: `/${BASENAME}${ROUTES.CATEGORY_LIST}`,
        matchPath: `/${BASENAME}/category`,
      },
    ].filter(Boolean),
    utils: [],
  };

  useSidebar(sidebar);
  return (
    <BrowserRouter>
      <RouterProvider>
        <Suspense fallback={<LoadingDots />}>
          <Routes>
            <Route path="/" element={null} />
            <Route path="*" element={<Navigate to={`${BASENAME}${ROUTES.PRODUCTS_LIST}`} />} />
            <Route
              path={`/${BASENAME}${ROUTES.CATALOG}`}
              element={<Navigate to={`/${BASENAME}${ROUTES.PRODUCTS_LIST}`} />}
            />
            <Route
              path={`/${BASENAME}/plp`}
              element={<Navigate to={`/${BASENAME}${ROUTES.PRODUCTS_LIST}`} />}
            />
            <Route
              path={`${BASENAME}${ROUTES.PRODUCTS_LIST}`}
              element={<CatalogListPage defaultTab={TabValues.PRODUCTS} />}
            />
            <Route
              path={`${BASENAME}${ROUTES.ITEMS_LIST}`}
              element={
                <ProtectedRoute
                  element={<CatalogListPage defaultTab={TabValues.ITEMS} />}
                  hasPermission={hasAccessToReadItems}
                />
              }
            />
            <Route
              path={`${BASENAME}${ROUTES.PRODUCT_DETAILS}/:${RouterParams.PRODUCT_ID}/:${RouterParams.COUNTRY}`}
              element={
                <ProtectedRoute element={<ProductPage />} hasPermission={hasAccessToReadProduct} />
              }
            />
            <Route
              path={`${BASENAME}${ROUTES.PRODUCT_DETAILS}/:${RouterParams.PRODUCT_ID}${ROUTES.VARIANT_DETAILS}/:${RouterParams.VARIANT_ID}/:${RouterParams.COUNTRY}`}
              element={
                <ProtectedRoute element={<VariantPage />} hasPermission={hasAccessToReadProduct} />
              }
            />
            <Route
              path={`${BASENAME}${ROUTES.PRODUCT_DETAILS}/:${RouterParams.PRODUCT_ID}${ROUTES.VARIANT_CREATION}/:${RouterParams.COUNTRY}`}
              element={
                <ProtectedRoute
                  element={<CreateVariantPage />}
                  hasPermission={hasAccessToCreateProduct}
                />
              }
            />
            <Route
              path={`${BASENAME}${ROUTES.PRODUCT_CREATION}`}
              element={
                <ProtectedRoute
                  element={<CreateProductPage />}
                  hasPermission={hasAccessToCreateProduct}
                />
              }
            />
            <Route
              path={`/${BASENAME}${ROUTES.BULK_LIST}`}
              element={
                <ProtectedRoute
                  element={<BulkImporterPage />}
                  hasPermission={hasAccessToReadBulk}
                />
              }
            />
            <Route path={`/${BASENAME}${ROUTES.BULK_CREATION}`} element={<ImportFilePage />} />
            <Route
              path={`/${BASENAME}${ROUTES.CATEGORY_LIST}`}
              element={
                <ProtectedRoute
                  element={<CategoryListPage />}
                  hasPermission={hasAccessToReadCategory}
                />
              }
            />
            <Route
              path={`/${BASENAME}${ROUTES.CATEGORY_CREATION}/:${RouterParams.COUNTRY}/:${RouterParams.STORE}`}
              element={
                <ProtectedRoute
                  element={<CreateCategoryPage />}
                  hasPermission={hasAccessToWriteCategory}
                />
              }
            />
            <Route
              path={`/${BASENAME}${ROUTES.CATEGORY_DETAILS}/:${RouterParams.COUNTRY}/:${RouterParams.CATEGORY_ID}`}
              element={
                <ProtectedRoute
                  element={<CategoryDetailsPage />}
                  hasPermission={hasAccessToReadCategory}
                />
              }
            />
            <Route
              path={`/${BASENAME}${ROUTES.ITEM_DETAILS}/:${RouterParams.COUNTRY}/:${RouterParams.ITEM_ID}`}
              element={
                <ProtectedRoute
                  element={<ItemDetailsPage />}
                  hasPermission={hasAccessToReadItems}
                />
              }
            />
          </Routes>
        </Suspense>
      </RouterProvider>
    </BrowserRouter>
  );
};
export default Router;
