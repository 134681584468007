import BadgeCounter from '@components/badge/Counter';
import { MAX_SELLERS_SEARCH } from '@config/constants';
import { Button, Heading, ListItem, Paragraph, TextButton, Tooltip } from '@hexa-ui/components';
import { X } from '@hexa-ui/icons';
import SellerFilterTab from 'components/filterTabs/SellerFilterTab/SellerFilterTab';
import StatusFilterTab from 'components/filterTabs/StatusFilterTab/StatusFilterTab';
import { useStore } from 'effector-react';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { ItemsFilterProps } from 'stores/itemsList/ItemsListState';
import sellersStore from 'stores/sellers/SellersStore';
import { ItemsFilterTabs } from '../../FilterTable';
import {
  FilterPopover,
  FilterSection,
  Footer,
  FooterButtonContainer,
  Nav,
  NavHeader,
  NavItem,
  TextItem,
} from './Popover.styles';

interface PopoverProps {
  filter: ItemsFilterProps;
  initialTab: ItemsFilterTabs;
  enabledTabs: ItemsFilterTabs[];
  onApply?: (filters: Partial<ItemsFilterProps>) => void;
  onClear?: () => void;
  onCancel?: () => void;
}

function Popover({
  filter,
  initialTab,
  enabledTabs,
  onApply,
  onClear,
  onCancel,
}: Readonly<PopoverProps>): React.ReactElement {
  const isTabEnaled = (tab: ItemsFilterTabs) => {
    return enabledTabs.includes(tab);
  };
  const { defaultSeller } = useStore(sellersStore);
  const { formatMessage } = useIntl();
  const [selectedSellers, setSelectedSellers] = useState(filter.sellers);
  const [status, setStatus] = useState(filter.status);
  const [tab, setTab] = useState(isTabEnaled(initialTab) ? initialTab : enabledTabs[0]);

  function onVendorCheckedChange(value) {
    if (selectedSellers.some((seller) => seller.vendorId === value.vendorId)) {
      setSelectedSellers(selectedSellers.filter((seller) => seller.vendorId !== value.vendorId));
      return;
    }
    if (selectedSellers.length >= MAX_SELLERS_SEARCH) {
      return;
    }
    setSelectedSellers([...selectedSellers, value]);
  }

  function onStatusCheckedChange(value) {
    if (status === value) {
      setStatus('');
      return;
    }
    setStatus(value);
  }

  const handleApply = () => {
    onApply({ status, sellers: selectedSellers });
  };

  const handleClear = () => {
    setStatus('');
    setSelectedSellers(defaultSeller ? [defaultSeller] : []);
    onClear?.();
  };

  const handleCancel = () => {
    onCancel();
  };

  const renderSubmitButton = (disabled: boolean) => (
    <Button onClick={handleApply} disabled={disabled}>
      {formatMessage({ id: 'Popover.APPLY' })}
    </Button>
  );

  return (
    <FilterPopover>
      <Nav>
        <NavHeader>
          <Heading size="H4">{formatMessage({ id: 'Popover.FILTER' })}</Heading>
          <Paragraph colortype="secondary" size="small">{`${
            (filter.status ? 1 : 0) + filter.sellers.length
          } ${formatMessage({ id: 'Popover.APPLIED' })}`}</Paragraph>
        </NavHeader>
        {isTabEnaled(ItemsFilterTabs.STATUS) && (
          <NavItem selected={tab === ItemsFilterTabs.STATUS}>
            <ListItem
              as="div"
              spacing="large"
              variant="navigation"
              onClick={() => setTab(ItemsFilterTabs.STATUS)}
            >
              <TextItem>
                {formatMessage({ id: 'Popover.STATUS' })}
                {status?.length > 0 && <BadgeCounter counterLabel="1" size="large" color="blue" />}
              </TextItem>
            </ListItem>
          </NavItem>
        )}
        {isTabEnaled(ItemsFilterTabs.SELLER) && (
          <NavItem selected={tab === ItemsFilterTabs.SELLER}>
            <ListItem
              as="div"
              spacing="large"
              variant="navigation"
              onClick={() => setTab(ItemsFilterTabs.SELLER)}
            >
              <TextItem>
                {formatMessage({ id: 'Popover.SELLER' })}
                {selectedSellers?.length > 0 && (
                  <BadgeCounter counterLabel={selectedSellers.length} size="large" color="blue" />
                )}
              </TextItem>
            </ListItem>
          </NavItem>
        )}
      </Nav>

      <FilterSection>
        {tab === ItemsFilterTabs.STATUS ? (
          <StatusFilterTab status={status} onStatusCheckedChange={onStatusCheckedChange} />
        ) : (
          <SellerFilterTab
            callback="seller"
            selectedItems={{ seller: selectedSellers }}
            onVendorCheckedChange={onVendorCheckedChange}
          />
        )}
      </FilterSection>
      <Footer>
        <TextButton icon={X} onClick={handleClear} iconPosition="leading">
          {formatMessage({ id: 'Popover.CLEAR_ALL' })}
        </TextButton>
        <FooterButtonContainer>
          <Button onClick={handleCancel} variant="secondary">
            {formatMessage({ id: 'Popover.CANCEL' })}
          </Button>
          {selectedSellers.length === 0 && defaultSeller && (
            <Tooltip text={formatMessage({ id: 'Popover.SELECT_SELLER' })} placement="top">
              {renderSubmitButton(true)}
            </Tooltip>
          )}
          {(selectedSellers.length > 0 || !defaultSeller) && renderSubmitButton(false)}
        </FooterButtonContainer>
      </Footer>
    </FilterPopover>
  );
}

export default Popover;
