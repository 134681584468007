import { Skeleton } from '@components';
import {
  FileUploader as FileUploaderComponent,
  FileUploaderProps,
  HandleFileUpload,
  Zones,
} from '@content-shared-components/file-uploader';
import { useRef } from 'react';
import { useFileUploader } from './hooks/useFileUploader';

export type CustomFileUploaderProps = {
  country: string;
  setIsUploading?: (isUploading: boolean) => void;
  isUploading?: boolean;
  clearErrorOnClear?: boolean;
  apiErrorMessageId?: string;
  requiredErrorMessageId?: string;
  onUploadFinished?: (newFileUrl: string) => void | Promise<void>;
  externalError?: string | boolean;
  isLoading?: boolean;
} & Omit<FileUploaderProps, 'zone'>;

const defaultStyle = { height: 376, width: '100%', maxWidth: '100%' };

export const FileUploader = ({
  country,
  clearErrorOnClear = false,
  apiErrorMessageId,
  requiredErrorMessageId,
  initialImageUrl,
  disabled,
  isEditable,
  maxFileSize = 2 * 1024 * 1024,
  onClear,
  onUploadFinished,
  style = defaultStyle,
  objectFit = 'contain',
  setIsUploading,
  isUploading,
  externalError,
  isLoading,
  ...restProps
}: CustomFileUploaderProps): JSX.Element => {
  const fileRef = useRef<HandleFileUpload>(null);

  const {
    handleDropAccepted,
    handleClear,
    error,
    isUploading: uploading,
  } = useFileUploader({
    handleFileUpload: () => fileRef.current?.handleFileUpload(),
    onUploadFinished,
    apiErrorMessageId,
    requiredErrorMessageId,
    clearErrorOnClear,
    onClear,
    setIsUploading,
    isUploading,
    externalError,
  });

  return (
    <Skeleton
      height={`${style.height}px`}
      width="100%"
      id="file-uploader-skeleton"
      imageShape="rectangle"
      variant="image"
      isLoading={isLoading}
    >
      <FileUploaderComponent
        ref={fileRef}
        onClear={handleClear}
        maxFileSize={maxFileSize}
        style={{
          ...defaultStyle,
          ...style,
        }}
        zone={country?.toLowerCase() as Zones}
        objectFit={objectFit}
        error={error}
        onDropAccepted={handleDropAccepted}
        isEditable={isEditable}
        disabled={disabled || uploading}
        initialImageUrl={initialImageUrl}
        {...restProps}
      />
    </Skeleton>
  );
};
