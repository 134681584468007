import { MAX_SELLERS_SEARCH } from '@config/constants';
import { Checkbox, LoadingDots, Paragraph, SearchField, Tooltip } from '@hexa-ui/components';
import useDebounce from '@hooks/useDebounce/useDebounce';
import { getSellers } from '@services/sellers/SellersService';
import { getTotalPagesToLoad } from '@utils/getTotalPagesToLoad';
import EmptyImage from 'assets/no-filter-results.svg';
import { Image } from 'components';
import { useStore } from 'effector-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  getSellersDone,
  getSellersWithFilterDone,
  resetAllSellersWithFilter,
  setIsLoadingSellers,
  setIsLoadingSellersWithFilter,
  setPagesToLoad,
  setPagesToLoadWithFilter,
} from 'stores/sellers/SellersEvents';
import sellersStore from 'stores/sellers/SellersStore';
import {
  EmptyContainer,
  LoadingContainer,
  ScrollableDivContainer,
  SearchInputContainer,
  SellerItemContainer,
  StyledParagraph,
} from './SellerFilterTab.styles';

interface SellerFilterTabProps {
  selectedItems: {
    manufacturer?: Sellers[];
    seller?: Sellers[];
  };
  onVendorCheckedChange: (value: Sellers, callback: 'manufacturer' | 'seller') => void;
  callback: 'manufacturer' | 'seller';
}
const DEBOUNCE_TIME = 500;

function SellerFilterTab({
  selectedItems,
  onVendorCheckedChange,
  callback,
}: Readonly<SellerFilterTabProps>) {
  const {
    allSellers,
    pagination: paginationFromStore,
    allSellersWithFilter,
    paginationWithFilter,
    isLoading: isSellersLoading,
    isLoadingWithFilter,
    pagesToLoad,
    pagesToLoadWithFilter,
  } = useStore(sellersStore);

  const [isClearLoading, setIsClearLoading] = useState(false);
  const [searchSellerValue, setSearchSellerValue] = useState('');
  const [debouncedSearchValue, isDebounceLoading] = useDebounce<string>(
    searchSellerValue,
    DEBOUNCE_TIME
  );

  const scrollableRef = useRef(null);
  const { formatMessage } = useIntl();

  const currentSelected = selectedItems[callback] || [];
  const sellersToDisplay = useMemo(() => {
    return debouncedSearchValue ? allSellersWithFilter : allSellers;
  }, [debouncedSearchValue, allSellersWithFilter, allSellers]);

  const pagination = debouncedSearchValue ? paginationWithFilter : paginationFromStore;
  const isSellersNotEmpty = sellersToDisplay?.length > 0;
  const isListLoading = isSellersLoading || isLoadingWithFilter || isClearLoading;
  const shouldShowEmptyState = !isListLoading && !isSellersNotEmpty;
  const resultsToShow = pagination?.totalElements || 0;

  const handleChangeSearch = (event) => {
    setSearchSellerValue(event.target.value);
  };

  const handleClearSearch = () => {
    setIsClearLoading(true);
    resetAllSellersWithFilter();
    setSearchSellerValue('');
  };

  const shouldShowTooltip = (sellerItemFromList: Sellers) => {
    return (
      currentSelected.length >= MAX_SELLERS_SEARCH &&
      !currentSelected.some((seller) => seller.vendorId === sellerItemFromList.vendorId)
    );
  };

  const fetchMoreVendors = useCallback(async () => {
    if (pagesToLoad.length === 0) return;
    const nextPage = pagesToLoad.shift();
    await getSellers({ params: {}, page: nextPage }, getSellersDone, setIsLoadingSellers);
    setPagesToLoad(pagesToLoad);
  }, [paginationFromStore.page, searchSellerValue]);

  const fetchMoreVendorsWithFilter = useCallback(async () => {
    if (pagesToLoadWithFilter.length === 0) return;
    const nextPage = pagesToLoadWithFilter.shift();
    await getSellers(
      { params: { vendorName: debouncedSearchValue }, page: nextPage },
      getSellersWithFilterDone,
      setIsLoadingSellersWithFilter
    );
    setPagesToLoadWithFilter(pagesToLoadWithFilter);
  }, [debouncedSearchValue, pagesToLoadWithFilter]);

  useEffect(() => {
    if (isClearLoading) {
      setTimeout(() => {
        setIsClearLoading(false);
      }, DEBOUNCE_TIME);
    }
  }, [isClearLoading]);

  useEffect(() => {
    const fetchData = async () => {
      if (debouncedSearchValue !== '') {
        if (!isDebounceLoading) {
          resetAllSellersWithFilter();
          setIsLoadingSellersWithFilter(true);
          const response = await getSellers(
            { params: { vendorName: debouncedSearchValue }, page: 0 },
            (sellers) =>
              getSellersWithFilterDone({
                vendors: sellers.vendors || [],
                pagination: sellers.pagination,
              }),
            setIsLoadingSellersWithFilter
          );
          setIsLoadingSellersWithFilter(false);
          const totalPagesToLoad = getTotalPagesToLoad(response?.pagination?.totalPages);
          setPagesToLoadWithFilter(totalPagesToLoad);
        }
      } else if (callback === 'manufacturer' && allSellers.length === 0) {
        const response = await getSellers(
          { params: {}, page: 0 },
          getSellersDone,
          setIsLoadingSellers
        );
        const totalPagesToLoad = getTotalPagesToLoad(response?.pagination?.totalPages);
        setPagesToLoad(totalPagesToLoad);
      }
    };

    fetchData();
  }, [debouncedSearchValue, isDebounceLoading, callback, allSellers.length]);

  useEffect(() => {
    const handleScroll = () => {
      const { current } = scrollableRef;
      if (!current || isDebounceLoading) return;
      const bottom = current.scrollHeight - current.scrollTop <= current.clientHeight;
      if (bottom) {
        if (debouncedSearchValue) {
          fetchMoreVendorsWithFilter();
        } else {
          fetchMoreVendors();
        }
      }
    };
    const scrollableElement = scrollableRef.current;
    scrollableElement.addEventListener('scroll', handleScroll);
    return () => scrollableElement.removeEventListener('scroll', handleScroll);
  }, [fetchMoreVendors, fetchMoreVendorsWithFilter, debouncedSearchValue, isDebounceLoading]);

  useEffect(() => {
    if (debouncedSearchValue !== '') {
      resetAllSellersWithFilter();
      setPagesToLoadWithFilter([]);
    }
  }, [debouncedSearchValue]);

  return (
    <div data-testid="seller-filter-tab">
      <SearchInputContainer>
        <SearchField.Root
          data-testid="search-input-popover"
          value={searchSellerValue}
          onClear={handleClearSearch}
          onChange={handleChangeSearch}
          placeholder={
            callback === 'manufacturer'
              ? formatMessage({ id: 'SellerFilterTab.SEARCH_MANUFACTURER' })
              : formatMessage({ id: 'SellerFilterTab.SEARCH_SELLER' })
          }
        />
        <Paragraph colortype="secondary">
          {formatMessage({ id: 'SellerFilterTab.SHOWING_RESULTS' }, { results: resultsToShow })}
        </Paragraph>
      </SearchInputContainer>
      <ScrollableDivContainer data-testid="scrollable-div" ref={scrollableRef}>
        {!shouldShowEmptyState && (
          <>
            {sellersToDisplay.map((seller) => (
              <SellerItemContainer data-testid="seller-item-container" key={seller.vendorId}>
                {shouldShowTooltip(seller) ? (
                  <Tooltip
                    text={formatMessage({ id: 'SellerFilterTab.MAX_SELLERS_TOOLTIP' })}
                    placement="right"
                  >
                    <Checkbox
                      key={seller.vendorId}
                      checked={false}
                      data-testid={`chekbox-${seller.vendorId}`}
                      id={`chekbox-${seller.vendorId}`}
                      value={seller.vendorId}
                      disabled
                    />
                  </Tooltip>
                ) : (
                  <Checkbox
                    key={seller.vendorId}
                    checked={currentSelected.some((item) => item.vendorId === seller.vendorId)}
                    data-testid={`chekbox-${seller.vendorId}`}
                    id={`chekbox-${seller.vendorId}`}
                    value={seller.vendorId}
                    onCheckedChange={() => onVendorCheckedChange(seller, callback)}
                  />
                )}
                <Paragraph>{seller.name}</Paragraph>
              </SellerItemContainer>
            ))}
          </>
        )}
        {shouldShowEmptyState && (
          <EmptyContainer data-testid="empty-sellers">
            <Image id="empty-img-filter" src={EmptyImage} />
            <StyledParagraph size="small" alignment="center" colortype="secondary">
              {formatMessage({ id: 'SellerFilterTab.NO_RESULTS' })}
            </StyledParagraph>
          </EmptyContainer>
        )}
        {isListLoading && (
          <LoadingContainer>
            <Paragraph colortype="secondary">
              {formatMessage({ id: 'SellerFilterTab.LOADING' })}
            </Paragraph>
            <LoadingDots />
          </LoadingContainer>
        )}
      </ScrollableDivContainer>
    </div>
  );
}

export default SellerFilterTab;
