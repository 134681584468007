import { Paragraph, Skeleton } from 'components';
import { StatusCircle, StatusContainer } from './StatusIndicator.styles';

const StatusIndicator = ({
  id,
  text,
  status,
  'data-testid': testId,
  isLoading,
}: StatusIndicatorProps): React.ReactElement => {
  const shouldDisabledText = status === 'NONE';
  return (
    <Skeleton
      id={`skeleton-${id}`}
      variant="body"
      height="16px"
      width="250px"
      imageShape="rectangle"
      isLoading={isLoading}
    >
      <StatusContainer id={id} data-testid={id}>
        <StatusCircle data-testid={`status-circle-${id}`} type={status} />
        <Paragraph
          text={text}
          weight="normal"
          colorType={shouldDisabledText ? 'disabled' : 'primary'}
          data-testid={testId}
        />
      </StatusContainer>
    </Skeleton>
  );
};

export default StatusIndicator;
