import { AuditLogProvider } from '@/providers/AuditLogProvider/AuditLogProvider';
import { FeatureToggleProvider } from '@/providers/FeatureToggleProvider/FeatureToggleProvider';
import { InterPageActionProvider } from '@/providers/InterPageActionProvider/InterPageActionProvider';
import { LanguageProvider } from '@/providers/LanguageProvider/LanguageProvider';
import { createInstance, OptimizelyProvider } from '@optimizely/react-sdk';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useAnalyticsService } from 'admin-portal-shared-services';
import { ReactElement, useEffect } from 'react';
import { EnvConfig, EnvProvider } from './envProvider';
import { CustomGridContainer, GlobalStyles } from './GlobalStyles.styles';
import { IntlProvider } from './i18n/provider';
import Router from './Router';

interface EnvProps extends EnvConfig {
  CMS_HOST: string;
  CMS_TOKEN: string;
  optimizelyKey: string;
  segmentKey: string;
}

export const queryClient = new QueryClient();

export default function App(props: Readonly<EnvProps>): ReactElement {
  const wrapperProps = {
    ...props,
    CMS_HOST: '',
    CMS_TOKEN: '',
  };

  const optimizelyClient = createInstance({ sdkKey: wrapperProps.optimizelyKey });

  const analyticsService = useAnalyticsService();

  useEffect(() => {
    analyticsService.load(wrapperProps.segmentKey);
  }, [analyticsService, wrapperProps.segmentKey]);

  return (
    <QueryClientProvider client={queryClient}>
      <EnvProvider env={wrapperProps}>
        <OptimizelyProvider optimizely={optimizelyClient}>
          <FeatureToggleProvider optimizelyKey={wrapperProps.optimizelyKey}>
            <LanguageProvider>
              <IntlProvider>
                <GlobalStyles />
                <CustomGridContainer type="fluid" sidebar>
                  <InterPageActionProvider>
                    <AuditLogProvider>
                      <Router />
                    </AuditLogProvider>
                  </InterPageActionProvider>
                </CustomGridContainer>
              </IntlProvider>
            </LanguageProvider>
          </FeatureToggleProvider>
        </OptimizelyProvider>
      </EnvProvider>
    </QueryClientProvider>
  );
}
