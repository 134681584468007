import { BASE_MANAGEMENT_BFF_PATH, URLS } from 'Api/enum';
import { ApiService } from 'services/api/ApiService';
import {
  getSellersWithItemTypeDone,
  setIsLoadingSellersWithItemType,
} from 'stores/sellers/SellersEvents';
import transformSellersResponse from './utils/transformSellersResponse';

export const SELLERS_URL = `${BASE_MANAGEMENT_BFF_PATH}${URLS.VENDORS}`;
export const SELLERS_WITH_ITEM_TYPE_URL = `${BASE_MANAGEMENT_BFF_PATH}${URLS.VENDORS_WITH_ITEM_TYPE}`;

export interface SellersFilterParams {
  vendorName?: string;
  vendorId?: string;
  itemType?: string;
}

interface GetSellersParam {
  params?: SellersFilterParams;
  page?: number;
}

const transformParams = (params?: SellersFilterParams) => {
  if (!params) return null;
  const { vendorId, ...rest } = params;
  return vendorId ? { ...rest, vendorIds: vendorId } : rest;
};

const fetchSellers = async (
  url: string,
  setIsLoading: (isLoading: boolean) => void,
  doneEvent: (response: SellersResponse) => void,
  { params, page }: GetSellersParam,
  transformData?: (data: any) => SellersResponse
): Promise<SellersResponse> => {
  const headers = {
    country: localStorage.getItem('catalogAdmin-selectedCountry'),
  };

  const { get } = ApiService(headers);
  const pageSize = 50;
  setIsLoading(true);
  return get<SellersResponse>({ url, params: { ...transformParams(params), pageSize, page } })
    .then((response) => {
      const transformedResponse = transformData ? transformData(response) : response;
      doneEvent(transformedResponse);
      return transformedResponse;
    })
    .catch((error) => {
      throw error;
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export const getSellersWithItemType = async (params: GetSellersParam): Promise<SellersResponse> => {
  return fetchSellers(
    SELLERS_WITH_ITEM_TYPE_URL,
    setIsLoadingSellersWithItemType,
    getSellersWithItemTypeDone,
    params
  );
};

export const getSellers = async (
  params: GetSellersParam,
  doneEvent: (response: SellersResponse) => void,
  setIsLoading: (isLoading: boolean) => void
): Promise<SellersResponse> => {
  const sellersResponse = await fetchSellers(
    SELLERS_URL,
    setIsLoading,
    doneEvent,
    params,
    transformSellersResponse
  );
  return sellersResponse;
};
