import InputViewMode from '@components/InputViewMode/InputViewMode';
import { TextAreaProps } from '@components/types/TextArea';
import { TextAreaWrapper } from './TextArea.styles';

const TextArea = ({
  id,
  label,
  viewMode = false,
  disabled = false,
  ml,
  mb,
  mt,
  mr,
  className = 'input',
  tooltipText,
  tooltipPlacement,
  value,
  name,
  onChange,
  onBlur,
  optionalText,
  required,
  errorText,
  hasError,
  type,
  width,
  characterCounter,
  placeholder,
  min,
  style,
  isLoading,
}: TextAreaProps): React.ReactElement => {
  return (
    <>
      {!viewMode ? (
        <TextAreaWrapper
          id={id}
          data-testid={id}
          label={label}
          disabled={disabled}
          mr={mr}
          mt={mt}
          mb={mb}
          ml={ml}
          className={className}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          name={name}
          optionalText={optionalText}
          required={required}
          hasError={hasError}
          errorText={errorText}
          type={type}
          width={width}
          characterCounter={characterCounter}
          placeholder={placeholder}
          min={min}
          style={style}
        />
      ) : (
        <InputViewMode
          id={id}
          label={label}
          className={className}
          tooltipText={tooltipText}
          tooltipPlacement={tooltipPlacement}
          value={value as string}
          colorType={disabled ? 'disabled' : 'primary'}
          mr={mr}
          mt={mt}
          mb={mb}
          ml={ml}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export default TextArea;
