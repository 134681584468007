type IsLoading = boolean;

type isEnabled = boolean | undefined;

export type UseFeatureToggleReturnType = [isEnabled, IsLoading];

export enum Features {
  TOGGLE_MOCK = 'toggle-mock',
  CAD_SHOW_AI_DESCRIPTION = 'cad-show-ai-description',
  CAD_BULK_FILES_SIZE_LIMIT = 'cad-bulk-files-size-limit',
  CAD_AUDITLOG_ENABLED = 'cad-auditlog-enabled',
  CAD_MULTILANGUAGE_ENABLED = 'cad-multilanguage-enabled',
  CAD_AI_RECOMMENDATIONS_ENABLED = 'cad-ai-recommendations-enabled',
}

export type FeatureToggleContextType = {
  useFeatureToggle: (featureName: Features) => UseFeatureToggleReturnType;
};

export type FeatureToggleProviderProps = {
  children: React.ReactNode;
  optimizelyKey: string;
};
