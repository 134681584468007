import { createStore } from 'effector';
import {
  getSellersDone,
  getSellersWithFilterDone,
  resetAllSellers,
  resetAllSellersWithFilter,
  resetDefaultSeller,
  resetSellersStore,
  setDefaultSeller,
  setIsLoadingSellers,
  setIsLoadingSellersForItemsTable,
  setIsLoadingSellersWithFilter,
  setPagesToLoad,
  setPagesToLoadWithFilter,
} from './SellersEvents';
import { SellersState } from './SellersState';

const initialState: SellersState = {
  sellers: [],
  allSellers: [],
  allSellersWithFilter: [],
  pagination: {
    page: 0,
    pageSize: 0,
    totalElements: 0,
    totalPages: 0,
  },
  paginationWithFilter: {
    page: 0,
    pageSize: 0,
    totalElements: 0,
    totalPages: 0,
  },
  isLoading: false,
  isLoadingWithFilter: false,
  isLoadingSellersForItemsTable: true,
  pagesToLoad: [0],
  pagesToLoadWithFilter: [0],
};

const sellersStore = createStore(initialState)
  .on(getSellersDone, (state, { pagination, vendors }) => ({
    ...state,
    sellers: vendors,
    allSellers: [...state.allSellers, ...vendors],
    isLoading: false,
    pagination,
  }))
  .on(getSellersWithFilterDone, (state, { pagination, vendors }) => ({
    ...state,
    sellers: vendors,
    allSellersWithFilter: [...state.allSellersWithFilter, ...vendors],
    isLoadingWithFilter: false,
    paginationWithFilter: pagination,
  }))
  .on(setIsLoadingSellers, (state, isLoading) => ({ ...state, isLoading }))
  .on(setIsLoadingSellersWithFilter, (state, isLoading) => ({
    ...state,
    isLoadingWithFilter: isLoading,
  }))
  .on(setIsLoadingSellersForItemsTable, (state, isLoading) => ({
    ...state,
    isLoadingSellersForItemsTable: isLoading,
  }))
  .on(resetAllSellers, (state) => ({
    ...state,
    allSellers: initialState.allSellers,
  }))
  .on(resetAllSellersWithFilter, (state) => ({
    ...state,
    allSellersWithFilter: initialState.allSellersWithFilter,
  }))
  .on(setDefaultSeller, (state, defaultSeller) => ({
    ...state,
    defaultSeller,
  }))
  .on(resetDefaultSeller, (state) => ({
    ...state,
    defaultSeller: initialState.defaultSeller,
  }))
  .on(setPagesToLoad, (state, pagesToLoad) => ({
    ...state,
    pagesToLoad,
  }))
  .on(setPagesToLoadWithFilter, (state, pagesToLoad) => ({
    ...state,
    pagesToLoadWithFilter: pagesToLoad,
  }))

  .reset(resetSellersStore);
export default sellersStore;
